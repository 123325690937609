import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';
import {lighten} from "@mui/material";

const useUntoldAdvertisingStyles = createUseStyles((theme: Theme) => {
    return {
        wrapperContainer: {
            backgroundColor: theme.colors.dark_chocolate,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        },
        wrapperContainerItem: {},
        containerComeUntold: {
            marginTop: theme.spacing(5),
        },
        containerS1: {
            maxHeight: '80vh',
            height: '80vh',
            backgroundColor: theme.colors.dark_chocolate,
            borderWidth: theme.spacing(5),
            borderStyle: 'solid',
            borderColor: theme.colors.latte_cream,
            [theme.breakpoints.down("lg")]: {
                padding: `${theme.spacing(2)}`,
                maxHeight: '75vh',
                height: '75vh',
            },
            '& $subtitleTeaser': {
                textAlign: 'center',
                padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
                [theme.breakpoints.down('lg')]: {
                    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
                },
                '& .textGold': {
                    color: theme.colors.desert_sand_gold,
                    display: "block",
                    fontWeight: 'normal',
                }
            },
        },
        containerS1Item: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            width: '100vw',
        },
        wrapperImgPrize: {
            padding: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                paddingTop: theme.spacing(0),
            }
        },
        teaserTitleUntold: {
            width: '100%',
            height: "auto",
            maxWidth: '650px',
        },
        containerS1ItemImgPrize: {
            display: 'inline-block',
            width: '100%',
            maxWidth: '800px',
            [theme.breakpoints.down('lg')]: {
                margin: `${theme.spacing(5)} ${theme.spacing(3)}`,
            }
        },
        logoKozelMeet: {
            maxWidth: '600px',
            width: '100%',
            marginTop: `-${theme.spacing(10)}`,
        },
        sectionKozelMeetTitle: {
            fontFamily: theme.fonts.apex,
            color: theme.palette.primary.light,
            fontSize: 'max(2vh,2vw)',
            paddingTop: theme.spacing(2),
            fontWeight: 'bold',
            '& span': {
                color: theme.colors.desert_sand_gold,
            }
        },
        sectionKozelMeetText: {
            fontFamily: theme.fonts.apex,
            color: theme.palette.primary.light,
            fontSize: 'max(1.5vh,1.5vw)',
            paddingTop: theme.spacing(5),
            textAlign: "center",
        },
        titleTeaser: {
            fontFamily: theme.fonts.neonLight,
            fontSize: 'max(3vh,3vw)',
            color: theme.colors.latte_cream,
        },
        subtitleTeaser: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.colors.latte_cream,
            fontWeight: 'bold',
        },
        wrapperButton: {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: theme.spacing(10),
        },
        button: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.colors.dark_chocolate,
            backgroundColor: theme.colors.latte_cream,
            borderColor: theme.colors.latte_cream,
            borderWidth: '1px',
            borderStyle: 'solid',
            marginTop: theme.spacing(3),
            padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            textTransform: 'uppercase',
            '&:hover': {
                backgroundColor: theme.colors.dark_chocolate,
                color: theme.colors.latte_cream,
                borderColor: theme.colors.latte_cream,
            },
            [theme.breakpoints.down("lg")]: {
                padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
            }
        },
        teaserUntoldTitle: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vh,3vw)',
            color: theme.colors.latte_cream,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            marginTop: theme.spacing(5),
            lineHeight: 1,
        },
        teaserUntoldLogo: {
            maxWidth: '390px',
            maxHeight: '405px',
            width: '100%',
            height: 'auto',
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(0),
        },
        teaserUntoldText: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.colors.latte_cream,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: theme.spacing(3),
        },
        commercialText: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vh,1.5vw)',
            marginTop: theme.spacing(5),
            color: theme.colors.latte_cream,
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(1),
            }
        },
        arrow: {
            maxHeight: '150px',
            width: '100%',
            height: 'auto',
            [theme.breakpoints.down('lg')]: {
                maxHeight: '100px',
            }
        },
        arrowMirror: {
            transform: 'scaleX(-1)',
        },
        wrapperImgInf: {
            padding: theme.spacing(5),
            borderRightWidth: '2px',
            borderRightStyle: 'solid',
            borderRightColor: theme.colors.latte_cream,
            [theme.breakpoints.down('lg')]: {
                borderRight: 'none',
                padding: theme.spacing(1),
            }
        },
        img: {
            width: '100%',
            height: 'auto',
        },
        wrapperTitle: {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: theme.spacing(2),
            [theme.breakpoints.down('lg')]: {
                paddingBottom: theme.spacing(0),
            }
        },
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(5vw,5vh)',
            fontWeight: 'bold',
            color: theme.colors.latte_cream,
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(4vw,4vh)',
                padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(0)} `
            }
        },
        wrapperSubtitle: {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: theme.spacing(5)
        },
        subtitle: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2vw,2vh)',
            fontWeight: 'bold',
            color: lighten(theme.colors.latte_cream, 0.5),
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(1.5vw,1.5vh)',
                padding: `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(0)} `
            }
        },
        wrapperText: {
            padding: theme.spacing(5)
        },
        boxText1: {
            paddingBottom: theme.spacing(5),
        },
        text: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2vw,2vh)',
            color: theme.palette.primary.light,
            '& a': {
                color: theme.colors.desert_sand_gold,
            },
            [theme.breakpoints.down('lg')]: {
                textAlign: 'center',
            }
        }

    }
});

export default useUntoldAdvertisingStyles;