import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useCarouselStyles = createUseStyles((theme: Theme) => {
    return {
        carouselContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: `-${theme.spacing(10)}`,
            width: '100%',
            [theme.breakpoints.down("sm")]: {
                marginBottom: `-${theme.spacing(5)}`,
            }
        },
        carouselLeft: {
            order: 2,
        },
        carouselRight: {
            order: 2,
        },
        carousel: {
            width: '100%',
            height: 'auto',
            '& div': {
                display: 'flex',
                justifyContent: 'center',
                textAlign: "center",
                alignItems: "center",
            }
        },
        carouselImage: {
            maxWidth: '100%',
            height: 'auto',
            margin: 'auto',
            [theme.breakpoints.down('sm')]: {
                height: '50vh',
            }
        },
        indicatorContainer: {
            position: 'absolute',
            bottom: theme.spacing(2),
            textAlign: 'center',
            width: '100%',
        },
        indicator: {
            zIndex: 1000,
            margin: theme.spacing(0.5),
            color: theme.palette.secondary.main,
            '&:hover': {
                color: theme.palette.primary.main,
            }
        },
        activeIndicator: {
            color: theme.palette.primary.main,
        },
    }
});

export default useCarouselStyles;