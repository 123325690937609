import React from "react";
import {Grid, Link, Typography} from "@mui/material";
import useUntoldAdvertisingStyles from "../styles/untold/untoldAdvertising";
import {NavLink} from "react-router-dom";
import {paths} from "../AppRouters";
import prize_untold from '../assets/images/untold/prize_untold.png';
import banner_text_2_rows from '../assets/images/untold/banner_text_2_rows.png';
import logo_untold_kozel_meeet from '../assets/images/untold/logo_untold_kozel_meeet.png';
import influencers_contrast from '../assets/images/untold/influencers_contrast.png';
import InfoIcon from '@mui/icons-material/Info';
import {useAppSelector} from "../stores/store";
import Carousel from "react-material-ui-carousel";
import useCarouselStyles from "../styles/kozel/carouselStyles";
import clsx from "clsx";

const UntoldAdvertising: React.FC = () => {
    const styles = useUntoldAdvertisingStyles();
    const carouselStyles = useCarouselStyles();
    const {isAuthenticated} = useAppSelector(state => state.auth);

    return (
        <Grid container className={styles.wrapperContainer}>
            <Grid item xs={12}>
                <Carousel
                    className={carouselStyles.carousel}
                    navButtonsAlwaysInvisible
                    animation={'fade'}
                    indicators={true}
                    autoPlay={false}
                    stopAutoPlayOnHover={true}
                    indicatorContainerProps={{
                        className: carouselStyles.indicatorContainer
                    }}
                    indicatorIconButtonProps={{
                        className: carouselStyles.indicator
                    }}
                    activeIndicatorIconButtonProps={{
                        className: carouselStyles.activeIndicator
                    }}
                >
                    <Grid container className={styles.containerS1}>
                        <Grid item xs={12} lg={6} className={styles.containerS1Item}>
                            <img src={banner_text_2_rows} alt={'banner_text_2_rows'}
                                 className={styles.teaserTitleUntold}/>
                            {<Typography className={styles.subtitleTeaser}>Cumpără Kozel, înscrie codul bonului pe site
                                și
                                câștigă premii de FESTIVAL</Typography>}
                            <NavLink to={paths.ncp} className={styles.button}>Înscrie bonul</NavLink>
                        </Grid>
                        <Grid item xs={12} lg={6} className={clsx(styles.containerS1Item, styles.wrapperImgPrize)}>
                            <img src={prize_untold} alt={'prize_untold'} className={styles.containerS1ItemImgPrize}/>
                        </Grid>
                    </Grid>
                    <Grid container className={styles.containerS1}>
                        <Grid item xs={12} className={styles.containerS1Item}>
                            <img src={banner_text_2_rows} alt={'banner_text_2_rows'}
                                 className={styles.teaserTitleUntold}/>
                            <Typography className={styles.subtitleTeaser}>
                                {isAuthenticated ? <>Creează-ți grupul și mergi cu gașca la zona Kozel Meeet și Barul
                                    gigant de lângă Roată!
                                    <span className={'textGold'}>Te așteaptă provocări ȚAPene</span></> : <>
                                    Fă parte din comunitatea KOZEL,
                                    participă la activări și câștigă puncte și premii!
                                    <span
                                        className={'textGold'}>E foarte ușor și rapid! Doar cu numărul de telefon!</span></>}
                            </Typography>
                            {isAuthenticated ?
                                <NavLink to={paths.myGroup} className={styles.button}>ÎMI CREEZ ECHIPA</NavLink> :
                                <NavLink to={paths.register} className={styles.button}>DEVIN MEMBRU</NavLink>}

                                <Typography className={styles.commercialText}><InfoIcon/>
                                    {isAuthenticated ?
                                        'fiecare prieten invitat în echipă aduce grupului 10 puncte' :
                                        'înscrierea în comunitate îți aduce automat 50 de puncte'}
                                </Typography>
                        </Grid>
                    </Grid>
                </Carousel>
            </Grid>
            <Grid item xs={12} lg={10} className={styles.wrapperContainerItem}>
                <Grid container className={styles.containerComeUntold}>
                    <Grid item xs={12}>
                        <img src={logo_untold_kozel_meeet} alt={'logo_untold_kozel_meeet'} className={styles.img}/>
                    </Grid>
                    <Grid item xs={12} className={styles.wrapperTitle}>
                        <Typography className={styles.title}>Așteptarea a luat sfârșit!</Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.wrapperSubtitle}>
                        <Typography className={styles.subtitle}>Echipele sunt formate, să înceapă
                            provocarea!</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} lg={6} className={styles.wrapperImgInf}>
                                <img src={influencers_contrast} alt={'influencers_contrast'} className={styles.img}/>
                            </Grid>
                            <Grid item xs={12} lg={6} className={styles.wrapperText}>
                                <Grid container>
                                    <Grid item xs={12} className={styles.boxText1}>
                                        <Typography className={styles.text}>
                                            <Link href={'https://www.instagram.com/lauramusuroaea/'}
                                                  target="_blank">@LauraMușuroaea</Link>,
                                            &nbsp;<Link href={'https://www.instagram.com/mariciu/'}
                                                        target="_blank">@Mariciu</Link>&nbsp;și&nbsp;
                                            <Link href={'https://www.instagram.com/vladgrigorescu/'}
                                                  target="_blank">@VladGrigorescu</Link>&nbsp;
                                            sunt deja aici cu echipele lor și au dat START provocărilor Kozel Meeet
                                            @UNTOLD!
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={styles.text}>Participă cu grupul tău de prieteni sau cu
                                            noii prieteni pe care ți-i faci aici, rezolvați toate provocările și
                                            bucurați-vă de gustul berii ȚAPene din Cehia!</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={styles.wrapperButton}>
                        <NavLink to={paths.kozelMeet} className={styles.button}>VEZI PROVOCĂRILE KOZEL MEET</NavLink>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UntoldAdvertising;