import React from 'react';
import UntoldAdvertising from "./UntoldAdvertising";
import HomepageVoceaRomaniei from "../components/HomepageVoceaRomaniei";
import {isBeforeAugust31} from "../utils/dateTimesUtils";

const Home: React.FC = () => {
    if (isBeforeAugust31()) {
        return (
            <UntoldAdvertising/>
        );
    }

    return (
        <HomepageVoceaRomaniei/>
    );
};

export default Home;
