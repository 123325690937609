import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import useHomepageVoceaRomanieiStyles from "../styles/homepageVoceaRomaniei";
import logo_late_cream from "../assets/images/logo_late_cream.png";
import logo_vocea_romaniei from "../assets/images/vocea_romaniei/logo_vocea_romaniei.png";
import teaser_img from "../assets/images/vocea_romaniei/goat.png";
import clsx from "clsx";
import TeaserFrame2 from "./TeaserFrame2";
import path_copite_reverse from '../assets/images/home/path_copite_reverse.png';
import path_copite from '../assets/images/home/path_copite.png';
import nr_4 from '../assets/images/home/nr_4.png';
import nr_3 from '../assets/images/home/nr_3.png';
import nr_2 from '../assets/images/home/nr_2.png';
import nr_1 from '../assets/images/home/nr_1.png';
import img_mix_beer from '../assets/images/home/img_mix_beer.png';
import img_mixologist from '../assets/images/home/img_mixologist.png';
import img_group_on_table from '../assets/images/home/img_group_on_table.png';
import img_wood_kozel from '../assets/images/home/img_wood_kozel.png';
import img_footer_kozel from '../assets/images/home/img_footer_kozel.png';
import {NavLink} from "react-router-dom";
import {paths} from "../AppRouters";
import {useAppDispatch, useAppSelector} from "../stores/store";
import {openLoginModal} from "../features/auth/authSlice";
import useIsScreenDownLg from "../hooks/useIsScreenDownLg";
import {isAfterSeptember16th, isBeforeNovember15th} from "../utils/dateTimesUtils";

interface PromoHomeStepProps {
    isReverseContentDir: boolean,
    imgPromo: React.ReactNode,
    imgNrPromo: React.ReactNode,
    boxText: React.ReactNode,
    boxDetails: React.ReactNode,
    isLastStep: boolean,
}


const PromoHomeStep: React.FC<PromoHomeStepProps> = (props) => {
    const styles = useHomepageVoceaRomanieiStyles();
    const {
        isReverseContentDir,
        imgPromo,
        imgNrPromo,
        boxText,
        boxDetails,
        isLastStep
    } = props;
    const isScreenDownLg = useIsScreenDownLg();

    return (
        <Grid container className={styles.promoStepContainer}>
            <Grid item xs={12} className={styles.promoStepItem}>
                <Grid container className={styles.promoStepSection}>
                    <Grid item xs={6} lg={5} order={!isScreenDownLg && isReverseContentDir ? 3 : 1} className={styles.promoBoxImgPromo}>
                        {imgPromo}
                    </Grid>
                    <Grid item xs={6} lg={2} order={2} className={styles.promoBoxImgNr}>
                        {imgNrPromo}
                    </Grid>
                    <Grid item xs={12} lg={5} order={!isScreenDownLg && isReverseContentDir ? 1 : 3} className={styles.promoBoxText}>
                        {boxText}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={styles.promoStepItem}>
                <Grid container>
                    <Grid item xs={6} lg={5} order={!isScreenDownLg && isReverseContentDir ? 3 : 1} className={styles.blank}>&nbsp;</Grid>
                    <Grid item xs={6} lg={2} order={2} className={styles.blank}>&nbsp;</Grid>
                    <Grid item xs={12} lg={5} order={!isScreenDownLg && isReverseContentDir ? 1 : 3} className={styles.promoBoxDetails}>
                        {boxDetails}
                    </Grid>
                </Grid>
            </Grid>
            {!isLastStep &&
                <Grid item xs={12} className={styles.promoStepItem}>
                    {isReverseContentDir ?
                        <img src={path_copite_reverse} alt={'path copite inapoi'} className={styles.imgPathCopite}/> :
                        <img src={path_copite} alt={'path copite'} className={styles.imgPathCopite}/>
                    }
                </Grid>
            }
        </Grid>
    )
}
const BannerVoiceRo: React.FC = () => {
    const styles = useHomepageVoceaRomanieiStyles();
    return (
        <Grid item xs={12} className={clsx(styles.item, styles.bg_vocea_romaniei)}>
            <Grid container
                  className={clsx(styles.boxBannerVoceaRomaniei)}>
                <Grid item xs={12} lg={5} className={styles.bannerWrapperItem}>
                    <Grid container className={styles.bannerContainer}>
                        <Grid item xs={12} className={styles.bannerItem}>
                            <img src={logo_late_cream} alt={'logo'} className={styles.bannerLogo}/>
                            <Typography className={styles.bannerTitle}>
                                te trimite la
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.bannerItem}>
                            <img src={logo_vocea_romaniei} alt={'logo vocea romaniei'}
                                 className={styles.bannerLogoVoiceRo}/>
                        </Grid>
                        <Grid item xs={12} className={styles.bannerItem}>
                            <Typography className={styles.bannerText}>Înscrie-te în tragerea la sorți
                                pentru biletele la semifinala și finala Vocea României!</Typography>
                        </Grid>
                        <Grid item xs={12} className={styles.bannerItem}>
                            <NavLink to={paths.voceaRomaniei} className={styles.bannerButton}>MĂ ÎNSCRIU</NavLink>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={5} className={styles.bannerWrapperItem}>
                    <img src={teaser_img} alt={'teaser_vocea_romaniei'}
                         className={styles.teaser_img}/>
                </Grid>
            </Grid>
        </Grid>
    );
}
const HomepageVoceaRomaniei: React.FC = () => {
    const styles = useHomepageVoceaRomanieiStyles();
    const dispatch = useAppDispatch();
    const {isAuthenticated} = useAppSelector(state => state.auth);
    const handleOpenLogin = () => {
        dispatch(openLoginModal());
    }

    return (
        <Grid container className={styles.containerWrapper}>
            <Grid item xs={12} className={styles.containerItemWrapper}>
                <Grid container className={styles.container}>
                    {isAfterSeptember16th() && isBeforeNovember15th() && <BannerVoiceRo />}
                    <Grid item xs={12} lg={8} className={clsx(styles.item,styles.itemTeaser)}>
                        <Typography className={styles.title}>Ce găsești în comunitatea kozel.ro?</Typography>
                    </Grid>
                    <Grid item xs={12} lg={8} className={clsx(styles.item,styles.itemTeaser)}>
                        <TeaserFrame2 title={''}>
                            <Grid container className={styles.teaserContentContainer}>
                                <Grid item xs={12}>
                                    <Typography className={styles.textTeaser}>Un loc unde te conectezi cu prietenii tăi,
                                        câștigați premii și experiențe împreună, te distrezi cu provocări
                                        surprinzătoare, înveți să faci super Mix-ul Kozel și să fii un Țapster
                                        veritabil, afli evenimente inedite din locațiile Kozel. Câștigi puncte și le
                                        schimbi cu premii și experiențe țapene!</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={styles.subtitleTeaser}>Și prieteni, și bere, și experiențe,
                                        și premii, și distracție! Le ai pe toate aici.</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={styles.titleTeaser}>Vino să vezi!</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button>INTRU ÎN COMUNITATE</Button>
                                </Grid>
                            </Grid>
                        </TeaserFrame2>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={8} className={clsx(styles.item)}>
                    <Grid container className={styles.boxPromoSteps}>
                        <Grid item xs={12}>
                            <PromoHomeStep
                                isReverseContentDir={false}
                                imgNrPromo={<img src={nr_1} alt={'nr 1'} className={styles.promoImgNr}/>}
                                isLastStep={false}
                                imgPromo={<img src={img_mixologist} alt={'Kozel mixologist'}
                                               className={styles.promoImg}  style={{ maxHeight: '330px', maxWidth: '390px' }} />}
                                boxText={<Typography className={styles.boxText}>Fă-ți cont pe site aici. Primești din
                                    start 50 de puncte și ești Junior Virtual Țapster momentan!</Typography>}
                                boxDetails={
                                    <Typography className={styles.boxDetails}>
                                        Mergi la&nbsp;
                                        {isAuthenticated ?
                                            <NavLink to={paths.profile} className={styles.navLink}>Profilul meu</NavLink>:
                                            <span className={styles.navLink} onClick={handleOpenLogin}>Profilul meu</span>
                                        }, completează-ți datele în
                                        contul tău și câștigă extra puncte!
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PromoHomeStep
                                isReverseContentDir={true}
                                imgNrPromo={<img src={nr_2} alt={'nr 2'} className={styles.promoImgNr}/>}
                                isLastStep={false}
                                imgPromo={<img src={img_mix_beer} alt={'Kozel beer mix'} className={styles.promoImg} style={{ maxHeight: '270px', maxWidth: '370px' }} />}
                                boxText={<Typography className={styles.boxText}>Descoperă MIX-ul țapăn Kozel și
                                    savurează-l cu gașca ta!</Typography>}
                                boxDetails={
                                    <Typography className={styles.boxDetails}>
                                        Mergi la <NavLink to={paths.servingRitual} className={styles.navLink}>Ritualuri de servire</NavLink>,
                                        descoperă Ritualurile de servire Kozel și impresionează-ți prietenii cu super
                                        MIX-urile de bere Kozel!
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PromoHomeStep
                                isReverseContentDir={false}
                                imgNrPromo={<img src={nr_3} alt={'nr 3'} className={styles.promoImgNr}/>}
                                isLastStep={false}
                                imgPromo={<img src={img_group_on_table} alt={'Kozel group on table'}
                                               className={styles.promoImg} style={{ maxHeight: '200px', maxWidth: '390px' }} />}
                                boxText={<Typography className={styles.boxText}>Invită-ți prietenii pe kozel.ro,
                                    creează-ți grupul și accesați super premii și experiențe
                                    împreună!</Typography>}
                                boxDetails={
                                    <Typography className={styles.boxDetails}>
                                        Odată ce ești logat pe site, mergi la secțiunea
                                        &nbsp;
                                        {isAuthenticated ?
                                            <NavLink to={paths.myGroup} className={styles.navLink}>Grupul meu</NavLink>:
                                            <span className={styles.navLink} onClick={handleOpenLogin}>Grupul meu</span>
                                        } și invită-ți prietenii să se alăture grupului. Grupul tău câștigă
                                        puncte pentru fiecare prieten nou venit.
                                        <br/>
                                        <br/>
                                        Folosiți punctele la evenimentele Kozel sau în locațiile Kozel, în timpul
                                        campaniilor speciale. Fii pe fază aici, pregătim suprize ȚAPene!
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PromoHomeStep
                                isReverseContentDir={true}
                                imgNrPromo={<img src={nr_4} alt={'nr 4'} className={styles.promoImgNr}/>}
                                isLastStep={true}
                                imgPromo={<img src={img_wood_kozel} alt={'Kozel wood balance'}
                                               className={styles.promoImg} style={{ maxHeight: '375px', maxWidth: '250px' }}/>}
                                boxText={<Typography className={styles.boxText}>Întotdeauna este loc pentru încă un
                                    prieten!</Typography>}
                                boxDetails={
                                    <Typography className={styles.boxDetails}>
                                        Mergi la secțiunea <NavLink to={paths.kozelMeet} className={styles.navLink}>Kozel Meeet</NavLink>,
                                        asigură-te că ai grupul format și fii pe fază, din septembrie pregătim surprize
                                        ȚAPene în locațiile Kozel, pentru tine și grupul tău de prieteni!
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={styles.containerItemWrapper}>
                <img src={img_footer_kozel} alt={'Kozel footer home'} className={styles.imgKozelFooter}/>
            </Grid>
        </Grid>
    );
}

export default HomepageVoceaRomaniei;